import { Button } from '@material-ui/core'
import React from 'react'
import { useNavigate } from "react-router-dom";
import 'components/Report/index.css'
import { Cached, Cancel, Error, Save, CalendarToday } from "@material-ui/icons";

export const Report = () => {
const navigate = useNavigate();
const backfun=()=>
{
navigate("/")


}
return (
<div>
<div>
<Button
startIcon={<CalendarToday />}
variant="contained"
type="button"
color="primary"
onClick={backfun}
className="reportbtn"

>
Back To Calendar
</Button>
</div>
<div>
<iframe class="iframe" src="https://datastudio.google.com/embed/reporting/088af5a0-6808-42d3-a608-713d63868b03/page/p_kkpy9mg61c" ></iframe>

</div>



</div>
)
}