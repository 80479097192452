import { DatePicker } from "@material-ui/pickers";
import React from "react";

const BlockDateInput = ({
  label,
  value,
  onChange,
  name,
  id,
  variant = "inline",
  autoOk = true,
}) => {
  const today = new Date(); // Get today's date
  return (
    <DatePicker
      autoOk={autoOk}
      variant={variant}
      label={label}
      inputVariant="outlined"
      size="small"
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      format={"MM/DD/YYYY"}
      fullWidth
      minDate={today} // Set minimum selectable date to today
    />
  );
};

export default BlockDateInput;
