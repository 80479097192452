import React from 'react'

import Tooltip from '@material-ui/core/tooltip'
import Zoom from '@material-ui/core/zoom'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const styleTooltip = {
  tooltip: {
    width: '300px',
    height: 'auto',
    fontSize: '18px'
  }
}

const CustomWidthTooltip = withStyles(styleTooltip)(Tooltip)

const TooltipBubble = ({ children, titleText, position = "left" }) => {
  return (
    <CustomWidthTooltip
      // title={titleText}
      title="This option is disabled because the appointment has expired or is not yet ready for use. Telehealth meeting rooms become available 15 minutes before the scheduled appointment time."
      arrow
      TransitionComponent={Zoom}
      placement={position}
    >
      <span>{children}</span>
    </CustomWidthTooltip>
  )
}

export default TooltipBubble
