import { TimePicker } from '@material-ui/pickers'
import React from 'react'

const TimeInput = ({ name, id, label, value, onChange, ampm = false }) => {
  return (
    <TimePicker
      autoOk
      variant='inline'
      label={label}
      inputVariant='outlined'
      size='small'
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      fullWidth
      ampm={ampm}
    />
  )
}

export default TimeInput
