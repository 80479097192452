import { normalize, schema } from "normalizr";

export const getDimensions = (elem) => {
  const { width, height } = elem.getBoundingClientRect();
  return { width, height };
};

export const normalizeAppointments = (appointments) => {
  const appointmentSchema = new schema.Entity(
    "appointments",
    {},
    {
      idAttribute: "SubmissionID",
    }
  );
  const appointmentArray = new schema.Array(appointmentSchema);
  return normalize(appointments, appointmentArray);
};
