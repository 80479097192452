import React from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'

import Interval from './Interval'
import { Add } from '@material-ui/icons'

const IntervalsInput = ({
  intervals,
  onIntervalInput,
  addNewInterval,
  removeInterval,
}) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
      xs={12}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography component='label'>Hours of availability:</Typography>
      </Grid>
      {intervals.map((interval, index) => {
        const [startTime, endTime, weekDays] = interval.split(' ')
        return (
          <Interval
            startTime={startTime}
            endTime={endTime}
            weekDays={weekDays}
            key={index}
            onIntervalInput={(value) => onIntervalInput({ value, index })}
            removeInterval={removeInterval}
            index={index}
          />
        )
      })}
      <Grid item container justifyContent='flex-end'>
        <Button
          color='primary'
          aria-label='delete'
          startIcon={<Add />}
          onClick={addNewInterval}
        >
          New Interval
        </Button>
      </Grid>
    </Grid>
  )
}

export default IntervalsInput
