import React, { useEffect, useState } from "react"
import {
  Checkbox,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
} from "@material-ui/core"

const ClinicDaysInput = ({ weekDays, onIntervalDay }) => {
  const [clinicDays, setClinicDays] = useState([
    { title: "Sunday", value: "Sun", checked: false },
    { title: "Monday", value: "Mon", checked: false },
    { title: "Tuesday", value: "Tue", checked: false },
    { title: "Wednesday", value: "Wed", checked: false },
    { title: "Thursday", value: "Thu", checked: false },
    { title: "Friday", value: "Fri", checked: false },
    { title: "Saturday", value: "Sat", checked: false },
  ])

  const prevCheckedWeekDays = weekDays.split(",")

  const newCheckedWeekDays = clinicDays
    .filter((day) => day.checked !== false)
    .map((day) => day.value)

  const handleCheckbox = (e) => {
    const checked = e.target.checked
    const value = e.target.value

    setClinicDays((prevClinicDays) => {
      return prevClinicDays.map((clinicDay) => {
        if (clinicDay.value === value) {
          clinicDay.checked = checked
        }
        return clinicDay
      })
    })

    const newCheckedWeekDays = clinicDays.filter(
      (clinicDay) => clinicDay.checked !== false
    )

    const stringWeekDays = newCheckedWeekDays.reduce((acc, next) => {
      if (acc === "") {
        return `${next.value}`
      }

      return `${acc.value ? acc.value : acc},${next.value}`
    }, "")

    onIntervalDay(stringWeekDays)
  }

  useEffect(() => {
    setClinicDays((prevClinicDays) => {
      return prevClinicDays.map((clinicDay) => {
        clinicDay.checked = false

        if (prevCheckedWeekDays.includes(clinicDay.value)) {
          clinicDay.checked = true
        }
        return clinicDay
      })
    })
  }, [weekDays])

  return (
    <>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor="weekdays">Weekdays</InputLabel>
        <Select
          fullWidth
          variant="outlined"
          label="Weekdays"
          multiple
          value={newCheckedWeekDays}
          renderValue={(selected) => selected.join(",")}
        >
          <List dense>
            {clinicDays.map((day) => (
              <ListItem key={day.title} button>
                <ListItemText primary={day.title} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    value={day.value}
                    onChange={handleCheckbox}
                    checked={day.checked}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Select>
      </FormControl>
    </>
  )
}

export default ClinicDaysInput
