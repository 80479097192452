import React from "react"
import Modal from "@material-ui/core/Modal"

import useStyles from "./style"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { Button } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import { DataBlock } from "./DataBlock"
import { DropdownButton } from "../Buttons"

import { sendReminder, CancelAppointment } from "../../../apis"

import ErrorNotification from "../../common/ErrorNotification"
import ClipboardButton from "../ClipboardButton"

import moment from "moment"

const createDateObjectFromTimeString = (timeString) =>
  moment(timeString, "h:m a").toDate()

const changeDateOnDateObject = (targetObject, sourceObject) => {
  const date = moment(sourceObject).date()
  const month = moment(sourceObject).month()
  const year = moment(sourceObject).year()
  return moment(targetObject).set({ date, month, year }).toDate()
}

const ModalAppointment = ({ appointment, handleEdit, handleClose, open }) => {
  const classes = useStyles()
  const [notificationOpen, setNotificationOpen] = React.useState(false)
  const [notificationMessage, setNotificationMessage] = React.useState("")
  const [notificationStatus, setNotificationStatus] = React.useState("")

  const trueEndTime = changeDateOnDateObject(
    createDateObjectFromTimeString(appointment.appointmentEndTime),
    appointment.appointmentStartTime
  )
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableAutoFocus={true}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.paper}
        >
          <Grid
            container
            direction="row"
            wrap="nowrap"
            className={classes.header}
            sx={12}
            alignItems="stretch"
            justifyContent="space-between"
            // spacing={2}
          >
            <Grid item sx={10}>
              <h3 className={classes.title}>Appointment Details</h3>
            </Grid>
            <Grid item sx={2}>
              <button onClick={handleClose} className={classes.closeButton}>
                <CancelIcon className={classes.closeIcon} />
              </button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.containerData}
          >
            <Grid
              container
              direction="row"
              wrap="nowrap"
              className={classes.buttonContainer}
            >
              {appointment.inPersonOrTelehealth === "Telehealth" ? (
                <Grid item>
                  {moment().diff(trueEndTime) > 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                      disabled
                    >
                      Resend <br />
                      reminder
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                      onClick={async () => {
                        setNotificationMessage("Sending Reminder...")
                        setNotificationStatus("info")
                        setNotificationOpen(true)
                        const value = await sendReminder(
                          appointment.appointmentID
                        )
                        setNotificationOpen(false)
                        if (value) {
                          setNotificationMessage("Reminder sent!")
                          setNotificationStatus("success")
                        } else {
                          setNotificationMessage("ERROR WITH SERVER")
                          setNotificationStatus("error")
                        }
                        setNotificationOpen(true)
                      }}
                    >
                      Resend <br />
                      telehealth link
                    </Button>
                  )}
                </Grid>
              ) : null}
              <Grid item>
                <DropdownButton
                  inPersonOrTelehealth={appointment.inPersonOrTelehealth}
                  classStyle={classes.buttonStyle}
                  onClickEdit={handleEdit}
                  setNotificationMessage={setNotificationMessage}
                  setNotificationStatus={setNotificationStatus}
                  setNotificationOpen={setNotificationOpen}
                  cancelAppointment={CancelAppointment}
                  appointment={appointment}
                  trueEndTime={trueEndTime}
                />
              </Grid>
              {appointment.inPersonOrTelehealth === "Telehealth" ? (
                <Grid item sx={12}>
                  {appointment.url === "" ||
                  moment().diff(
                    moment(appointment.appointmentStartTime).subtract(
                      15,
                      "minutes"
                    )
                  ) < 0 ||
                  moment().diff(moment(trueEndTime).add(15, "minutes")) > 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                      disabled
                    >
                      Open <br />
                      telehealth
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                      onClick={() =>
                        window.open(
                          appointment.url.replace("patient", "provider"),
                          "_blank"
                        )
                      }
                    >
                      Open <br />
                      telehealth
                    </Button>
                  )}
                </Grid>
              ) : null}
            </Grid>
            {appointment.inPersonOrTelehealth === "Telehealth" ? (
              <Grid item style={{ margin: "10px 5px 0 5px" }}>
                <ClipboardButton
                  value={appointment.idHash}
                  validation={
                    appointment.url === "" ||
                    moment().diff(
                      moment(appointment.appointmentStartTime).subtract(
                        15,
                        "minutes"
                      )
                    ) < 0 ||
                    moment().diff(moment(trueEndTime).add(15, "minutes")) > 0
                  }
                />
              </Grid>
            ) : null}
            <Box
              style={{
                height: "100%",
                maxHeight: "55%",
                zIndex: "19",
                overflow: "scroll",
              }}
            >
              <List xs={true} style={{}}>
                <ListItem className={classes.listItemStyle} disablePadding>
                  <DataBlock
                    dataName="Name"
                    content={appointment.name.replace(",", "")}
                  />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock dataName="Time" content={appointment.time} />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock dataName="Date" content={appointment.date} />
                </ListItem>
                <ListItem
                  className={classes.listItemStyle}
                  style={{
                    display: appointment.phone ? "inherit" : "none",
                  }}
                >
                  <DataBlock dataName="Phone" content={appointment.phone} />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock dataName="Clinic" content={appointment.clinic} />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock
                    dataName="Provider"
                    content={appointment.provider}
                  />
                </ListItem>
                <Grid item>
                  <DataBlock
                    dataName="Appointment Type"
                    content={appointment.appType}
                  />
                </Grid>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock
                    dataName="Telehealth or In-person"
                    content={appointment.inPersonOrTelehealth}
                  />
                </ListItem>
                <ListItem className={classes.listItemStyle}>
                  <DataBlock
                    dataName="Confirmation number"
                    content={appointment.patientID}
                  />
                </ListItem>
                {appointment.EMR === "" ? null : (
                  <ListItem className={classes.listItemStyle}>
                    <DataBlock dataName="EMR ID" content={appointment.EMR} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <ErrorNotification
        open={notificationOpen}
        title={notificationMessage}
        onClose={() => setNotificationOpen(false)}
        severity={notificationStatus}
      />
    </>
  )
}

export default ModalAppointment
