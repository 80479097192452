import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: ({ cellHeight }) => ({
    maxWidth: '100%',
    overflow: 'hidden',
    height: cellHeight,
    minHeight: 103,
  }),
  cellHeaderRoot: {
    padding: theme.spacing(0, 1),
  },
  listRoot: {
    padding: theme.spacing(0.25, 0),
    overflow: 'auto',
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
    backgroundColor: ({ Cancel }) => (Cancel ? '#dedede' : 'transparent'),

    '& .MuiListItemText-multiline': {
      margin: 0,
    },
  },
  listItemText: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontSize: 14,
    '& .MuiListItemText-secondary': {
      marginLeft: theme.spacing(0.5),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  truncateButton: {
    padding: theme.spacing(0, 1),
    borderRadius: 4,
  },
  date: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const ListItem = ({
  FirstName,
  LastName,
  Phone,
  SubmissionID,
  AppointmentStartTime,
  onClick,
  Cancel,
}) => {
  const time = moment(AppointmentStartTime).format('h:mma')
  const classes = useStyles({ Cancel })
  return (
    <MuiListItem
      className={classes.listItem}
      dense
      button
      onClick={() => onClick(SubmissionID)}
    >
      <ListItemText
        className={classes.listItemText}
        primary={`${time}`}
        secondary={`${FirstName} ${LastName}`}
        primaryTypographyProps={{
          variant: 'subtitle2',
        }}
        secondaryTypographyProps={{
          variant: 'subtitle2',
        }}
      />
    </MuiListItem>
  )
}

function MonthGridCell({
  appointments,
  dayNumberText,
  onClickAppointment,
  cellHeight,
}) {
  const classes = useStyles({ cellHeight })
  const CellHeader = ({ date, count }) => {
    return (
      <Grid
        className={classes.cellHeaderRoot}
        container
        justify='space-between'
      >
        <Grid component={Typography} align='left'>
          <Typography className={classes.date} variant='subtitle1'>
            {date}
          </Typography>
        </Grid>
        {count > 0 && (
          <Grid
            component={Typography}
            variant='subtitle1'
            align='right'
            onClick={() => onClickAppointment(appointments[0].SubmissionID)}
          >
            <Button size='small'>
              <Box component='span' fontWeight='fontWeightBold'>
                {count}&nbsp;
              </Box>
              {`appt${count === 1 ? '.' : 's.'}`}
            </Button>
          </Grid>
        )}
      </Grid>
    )
  }
  const slicedAppointments = []
  if (appointments.length === 1) slicedAppointments.push(appointments[0])
  else if (appointments.length > 1)
    slicedAppointments.push(appointments[0], appointments[1])
  return (
    <Grid className={classes.root} container direction='column' wrap='nowrap'>
      <CellHeader
        count={appointments.filter((appt) => appt?.Cancel !== true).length}
        date={dayNumberText}
      />
      <List className={classes.listRoot}>
        {slicedAppointments.map((appointment, index) => {
          if (appointment) {
            return appointments.length > 2 && index === 1 ? (
              <>
                <ListItem onClick={onClickAppointment} {...appointment} />
                <IconButton
                  size='small'
                  onClick={() => onClickAppointment(appointment.SubmissionID)}
                  className={classes.truncateButton}
                >
                  <MoreHorizIcon fontSize='small' />
                </IconButton>
              </>
            ) : (
              <ListItem onClick={onClickAppointment} {...appointment} />
            )
          } else return null
        })}
      </List>
    </Grid>
  )
}

MonthGridCell.propTypes = {}

export default MonthGridCell
