import { GET, POST } from "utils/httpMethods";
import axios, { CancelToken } from "axios";
import cloudFunctions from "./cloudfunctions.json";
import resolve from "resolve";
import { getCookie } from "utils/cookies";
let cancelToken;
let keyAPI = "?key=AIzaSyCcZqJxQr8cew_LA_dYrFxoD8Yj7gY9La8";
let keyAPIProd = "?key=AIzaSyBYVordko3TJjoTZXYzggQUX8YZ5qrvCNw";

// TODO: Hablar con los muchachos acerca de la api key para los appointments

const endpoints =
  process.env.NODE_ENV === "production"
    ? cloudFunctions.production
    : cloudFunctions.development;

export async function getAppointments({ startDate, endDate }, authToken) {
  console.log(authToken, "authToken");
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel();
  }
  cancelToken = CancelToken.source();
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/GetBSTIAppointment${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-All-BSTIAppointment`

    const response = await GET(endpoints.getAppointments, {
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      params: {
        startDate,
        endDate,
      },
      cancelToken: cancelToken.token,
    });

    return { message: response.data };
  } catch (e) {
    throw { message: e.message, cancelToken };
  }
}

export async function searchAppointments(searchText, authToken) {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/SearchBSTIAppointments${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-Search-BSTIAppointment`
    const response = await GET(endpoints.searchAppointments, {
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      params: {
        searchText,
      },
    });
    return { message: response.data };
  } catch (e) {
    throw { message: e.message };
  }
}

export async function filterAppointments({
  startDate,
  authToken,
  endDate,
  clinicName = "",
  apptType = "",
  provider = "",
}) {
  try {
    // provider = provider.replace(',', '')
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/FilterBSTIAppointments${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-BSTIAppointment-ByClinic`\

    const response = await GET(endpoints.filterAppointments, {
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      params: {
        startDate,
        endDate,
        clinicName,
        apptType,
        provider,
      },
    });
    return { message: response.data };
  } catch (e) {
    throw { message: e.message };
  }
}

export async function getFilterOptions(authToken) {
  try {
    const response = await GET(endpoints.getFilterOptions, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`, // Make sure to pass the token here
        "Content-Type": "application/json",
      },
    });
    console.log(response, "authToken123");
    return { message: response.data };
  } catch (e) {
    throw { message: e.message };
  }
}
export async function getSettingsFilterOptions(authToken) {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${apiUrls.API}/Scheduler/GetClinicNameApptTypeProvider${keyAPI}`
    //     : `${apiUrls.API}/CAL-B-GET-BSTIClinicsList`
    const response = await GET(endpoints.getSettingFilterOptions, {
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      // params: {
      //   authToken,
      // },
    });
    return { message: response.data };
  } catch (e) {
    throw { message: e.message };
  }
}

export async function sendReminder(appointmentID, authToken) {
  try {
    // let url =
    //   process.env.NODE_ENV === "production"
    //     ? `${reminderURL}/Scheduler/SendReminder${keyAPIProd}`
    //     : `${apiUrls.API}/NYC-B-Message-Trigger-ResendReminder`
    const rawResponse = await fetch(endpoints.sendReminder, {
      method: "POST",
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "Reminder-Patient",
        appointmentID,
      }),
    });

    // const content = await rawResponse.json();

    return true;
    // console.log(content);

    // return {message: content.data}
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

export async function CancelAppointment(apptData, authToken) {
  try {
    await fetch(endpoints.cancelAppointment, {
      method: "POST",
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...apptData,
        bodyType: "",
      }),
    });

    return true;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}

export const getRole = async (token) => {
  try {
    const res = await axios.post(endpoints.getRole,{ token },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res.data.role;
  } catch (error) {
    console.error(error);
  }
};

export const saveClinicSettings = async (query, authToken) => {
  try {
    const res = await axios.post(endpoints.saveClinicSettings, query, {
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClinicSettings = async (clinicName, authToken) => {
  try {
    const res = await axios.post(
      endpoints.getClinicSettings,
      {
        clinic_name: clinicName,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: `${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};
// Get API
export async function BlockApptsDisplay(
  name1,
  name2,
  date2,
  selectedClinicData,
  maxSlotsPerAppt,
  rollingDays,
  r_qID,
  authToken
) {
  let sendfront = [];
  try {
    console.log(
      endpoints.BlockApptsDisplay,
      name1,
      name2,
      date2,
      selectedClinicData,
      maxSlotsPerAppt,
      rollingDays,
      "getApi"
    );
    let data = JSON.stringify({
      first: name1,
      last: name2,
      date: date2,
      clinic: selectedClinicData,
      maxSlotsPerAppt: maxSlotsPerAppt,
      rollingDays: rollingDays,
      r_qID: r_qID,
    });
    let config = {
      method: "post",
      url: endpoints.BlockApptsDisplay,
      headers: {
        Accept: "application/json",
        Authorization: `${authToken}`,
        "Content-Type": "application/json",
        //'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkOWE1ZWY1YjEyNjIzYzkxNjcxYTcwOTNjYjMyMzMzM2NkMDdkMDkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzMzg1NzQ3MTM5ODczNjY5MjcxIiwiaGQiOiJwZW9wbGUuaGVhbHRoIiwiZW1haWwiOiJhcmllZm11aGFtbWFkQHBlb3BsZS5oZWFsdGgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IjVxNWU0TTQ0Sy1GU1NqdlpvdmdqbkEiLCJpYXQiOjE2ODUwMDkyMzksImV4cCI6MTY4NTAxMjgzOSwianRpIjoiMDVhYzFiZGFmNTgwOWQxOTIyYjEyMWM5MzcwMGY5ZWQxMWViYTBhNiJ9.KL5jJ_5X1qxW0s2PwirB9z6haW2eH6mq3ktS-Ro6pJiQjzQXNeMLieOfdHD2_ys705f-aNq0sLxaIKY-8I1X99LYV8EW6VWGA2wSP9sK_FLOqQvFimJW_UvQNdPkFazOK-QyckOID5JuWynZ91WfTJTlIAKOgVRBtrTy-edLtIIS6AzpaOas7MHfLYSPb7Kkm4CztIHBL_Vm6LViBbzHvla9r2om8ZY0u873HMi8rQJHTs6jiB5OQTQoJ26mHpRwTeNT3QTroaVP0zGCTXZmXY8RIQV9pObRfmxRmrrKQe5xrNCdY142tUCKNPft3ljPYZX7WWdht-X4DL2bmQLQpA'
      },
      data: data,
    };
    await axios
      .request(config)
      .then((response) => {
        sendfront.push(response.data);
        console.log(JSON.stringify(response.data), "response");
        // return JSON.stringify(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(sendfront, "response");
    return sendfront[0].message;
  } catch (error) {
    throw { message: error.message };
  }
}
//Post API
export const BlockApptsPost = async (
  name1,
  name2,
  date2,
  selectedClinicData,
  maxSlotsPerAppt,
  rollingDays,
  r_qID,
  authToken
) => {
  try {
    return new Promise((resolve, reject) => {
      console.log(
        endpoints.BlockApptsPost,
        name1,
        name2,
        date2,
        selectedClinicData,
        maxSlotsPerAppt,
        rollingDays,
        "test12"
      );
      let data = JSON.stringify({
        first: name1,
        last: name2,
        date: date2,
        clinic: selectedClinicData,
        maxSlotsPerAppt: maxSlotsPerAppt,
        rollingDays: rollingDays,
        r_qID: r_qID,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: endpoints.BlockApptsPost,
        headers: {
          Accept: "application/json",
          Authorization: `${authToken}`,
          "Content-Type": "application/json",
          //'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkOWE1ZWY1YjEyNjIzYzkxNjcxYTcwOTNjYjMyMzMzM2NkMDdkMDkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzMzg1NzQ3MTM5ODczNjY5MjcxIiwiaGQiOiJwZW9wbGUuaGVhbHRoIiwiZW1haWwiOiJhcmllZm11aGFtbWFkQHBlb3BsZS5oZWFsdGgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IjVxNWU0TTQ0Sy1GU1NqdlpvdmdqbkEiLCJpYXQiOjE2ODUwMDkyMzksImV4cCI6MTY4NTAxMjgzOSwianRpIjoiMDVhYzFiZGFmNTgwOWQxOTIyYjEyMWM5MzcwMGY5ZWQxMWViYTBhNiJ9.KL5jJ_5X1qxW0s2PwirB9z6haW2eH6mq3ktS-Ro6pJiQjzQXNeMLieOfdHD2_ys705f-aNq0sLxaIKY-8I1X99LYV8EW6VWGA2wSP9sK_FLOqQvFimJW_UvQNdPkFazOK-QyckOID5JuWynZ91WfTJTlIAKOgVRBtrTy-edLtIIS6AzpaOas7MHfLYSPb7Kkm4CztIHBL_Vm6LViBbzHvla9r2om8ZY0u873HMi8rQJHTs6jiB5OQTQoJ26mHpRwTeNT3QTroaVP0zGCTXZmXY8RIQV9pObRfmxRmrrKQe5xrNCdY142tUCKNPft3ljPYZX7WWdht-X4DL2bmQLQpA'
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          console.log(response, "statuss");
          resolve({ message: response.data, statusCode: response.status });
        })
        .catch((error, res) => {
          console.log(error, res);
          resolve({ error: error, statusCode: 500 });
        });
    });
  } catch (error) {
    throw { message: error.message };
  }
};

//Delete Api
export const BlockApptsDelete = async (
  name1,
  name2,
  date2,
  selectedClinicData,
  maxSlotsPerAppt,
  rollingDays,
  r_qID,
  authToken
) => {
  try {
    return new Promise((resolve, reject) => {
      console.log(
        endpoints.BlockApptsDelete,
        name1,
        name2,
        date2,
        selectedClinicData,
        maxSlotsPerAppt,
        rollingDays,
        r_qID,
        "test12"
      );
      let data = JSON.stringify({
        first: name1,
        last: name2,
        date: date2,
        clinic: selectedClinicData,
        maxSlotsPerAppt: maxSlotsPerAppt,
        rollingDays: rollingDays,
        r_qID: r_qID,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: endpoints.BlockApptsDelete,
        headers: {
          Accept: "application/json",
          Authorization: `${authToken}`,
          "Content-Type": "application/json",
          //'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjJkOWE1ZWY1YjEyNjIzYzkxNjcxYTcwOTNjYjMyMzMzM2NkMDdkMDkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEzMzg1NzQ3MTM5ODczNjY5MjcxIiwiaGQiOiJwZW9wbGUuaGVhbHRoIiwiZW1haWwiOiJhcmllZm11aGFtbWFkQHBlb3BsZS5oZWFsdGgiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IjVxNWU0TTQ0Sy1GU1NqdlpvdmdqbkEiLCJpYXQiOjE2ODUwMDkyMzksImV4cCI6MTY4NTAxMjgzOSwianRpIjoiMDVhYzFiZGFmNTgwOWQxOTIyYjEyMWM5MzcwMGY5ZWQxMWViYTBhNiJ9.KL5jJ_5X1qxW0s2PwirB9z6haW2eH6mq3ktS-Ro6pJiQjzQXNeMLieOfdHD2_ys705f-aNq0sLxaIKY-8I1X99LYV8EW6VWGA2wSP9sK_FLOqQvFimJW_UvQNdPkFazOK-QyckOID5JuWynZ91WfTJTlIAKOgVRBtrTy-edLtIIS6AzpaOas7MHfLYSPb7Kkm4CztIHBL_Vm6LViBbzHvla9r2om8ZY0u873HMi8rQJHTs6jiB5OQTQoJ26mHpRwTeNT3QTroaVP0zGCTXZmXY8RIQV9pObRfmxRmrrKQe5xrNCdY142tUCKNPft3ljPYZX7WWdht-X4DL2bmQLQpA'
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          resolve({ message: response.data, statusCode: response.status });
        })
        .catch((error, res) => {
          resolve({ error: error, statusCode: 500 });
          console.log(error, res, "error code ");
        });
    });
  } catch (error) {
    throw { message: error.message };
  }
};

export const updateAppointmentsData = async (payload) => {
  console.log(payload, "payloaddddd");
  try {
    var token = await getCookie("Authorization");
    console.log(token, "tokennnnn");
    const response = await axios.post(
      endpoints.updateAppointmentsData,
      payload,
      {
        headers: {
          Accept: "application/json",
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
