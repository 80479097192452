import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Delete } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import { DateInput } from "../Common"

const useStyles = makeStyles({
  holidays: {
    maxHeight: "200px",
    overflowY: "scroll",
    "& > li:hover": {
      backgroundColor: "#e5e5e5",
    },
  },
})

const HolidaysInput = ({ holidays, onHolidayInput }) => {
  const [controlledHolidays, setControlledHolidays] = useState([])
  const [newHoliday, setNewHoliday] = useState(null)
  const classes = useStyles()
  const handleAddHoliday = () => {
    if (!newHoliday) return
    onHolidayInput({
      value: newHoliday.format("YYYY-MM-DD"),
      action: "add",
    })
    setNewHoliday(null)
  }

  const handleRemoveHoliday = (holiday) => {
    onHolidayInput({ value: holiday, action: "remove" })
  }

  useEffect(() => {
    console.log(holidays)

    const sortedByDateHolidays = holidays.sort((acc, next) => {
      return new Date(acc.split(" ")[0]) - new Date(next.split(" ")[0])
    })

    setControlledHolidays(sortedByDateHolidays)
  }, [holidays])

  return (
    <>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography component="label">Holidays:</Typography>
      </Grid>
      <Grid item xs={8} sm={8} md={5}>
        <DateInput
          label="Holiday date"
          value={newHoliday}
          onChange={setNewHoliday}
          autoOk
        />
      </Grid>
      <Grid item xs={4} sm={4} md={2}>
        <Button
          type="button"
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleAddHoliday}
          disabled={!newHoliday}
        >
          Add Holiday
        </Button>
      </Grid>
      <Grid item xs={12} sm md={5}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel htmlFor="holidays">Remove holiday</InputLabel>
          <Select
            label="Remove holiday"
            multiple
            value={[]}
            variant="outlined"
            fullWidth
          >
            <List dense className={classes.holidays}>
              {controlledHolidays.map((holiday) => {
                // Hide expired holidays
                if (new Date(holiday.split(" ")[0]) <= Date.now()) {
                  return null
                }

                return (
                  <ListItem key={holiday}>
                    <ListItemText primary={holiday?.split(" ")[0]} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveHoliday(holiday)}
                        color="secondary"
                        size="small"
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}

export default HolidaysInput
