import React from "react"

import { EditButton, CancelButton } from "../index"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    height: "auto",
    width: "100%",
    background: "white",
    zIndex: "50000000000",
    borderRadius: "5px",
    border: "2px solid #00BEB5",
    padding: "0.4rem",
  },
  containerUL: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  boxStyleLI: {
    cursor: "pointer",
    padding: "0.2rem",
  },
}))

const DropdownCard = ({
  classStyle,
  setOpen,
  onClickEdit,
  setNotificationMessage,
  setNotificationStatus,
  setNotificationOpen,
  cancelAppointment,
  updateAppointments,
  disableEditButton,
  appointment,
  inPersonOrTelehealth,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <ul className={styles.containerUL}>
        <li className={styles.boxStyleLI} onClick={() => setOpen(false)}>
          <EditButton
            className={classStyle}
            onClickEdit={onClickEdit}
            appointment={appointment}
            SubmissionID={appointment.SubmissionID}
            text="View and Edit"
            disabled={disableEditButton}
            WasMadeByAProvider={
              appointment?.GetPageURL?.includes("calendar.coherentrx") ||
              appointment?.GetPageURL?.includes("provider")
            }
          />
        </li>
        {/* Entregar SubmittionDate cuando se haga cancel appointment */}
        <li className={styles.boxStyleLI} onClick={() => setOpen(false)}>
          <CancelButton
            deleteAppt={disableEditButton}
            className={classStyle}
            appointment={appointment}
            cancelAppointment={cancelAppointment}
            setNotificationMessage={setNotificationMessage}
            setNotificationStatus={setNotificationStatus}
            setNotificationOpen={setNotificationOpen}
            onClickEdit={onClickEdit}
            updateAppointments={updateAppointments}
          />
        </li>
      </ul>
    </div>
  )
}

export default DropdownCard
