import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  formControl: {
    zIndex: "3",
  },
  label: {
    whiteSpace: "nowrap",
    transform: "translate(14px, 10px) scale(1)",
    fontSize: "0.875rem",
  },
  selectRoot: {
    maxHeight: "50vh",
    width: "100%",
    paddingTop: theme.spacing(1.0625),
    paddingBottom: theme.spacing(1.0625),
    zIndex: 3,
  },
  resetButton: {
    fontWeight: theme.typography.fontWeightBold,
    cursor: "pointer",
  },
}))

export default function Filter({
  id,
  options = [],
  label,
  onChange,
  resetFilter,
}) {
  const classes = useStyles()

  const handleCheckbox = (e) => {
    onChange({ value: e.target.value, checked: e.target.checked })
  }
  return (
    <FormControl
      size="small"
      fullWidth
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel
        htmlFor={`${label}`}
        className={classes.label}
        id={`${id}-label`}
      >
        {label}
      </InputLabel>
      <Select
        fullWidth
        classes={{ root: classes.selectRoot }}
        label={label}
        labelId={`${id}-label`}
        value={options.filter((option) => option.checked === true)}
        multiple
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "50vh",

              transform: options.length
                ? "translateY(75px)"
                : "translateY(0px)",
            },
          },
        }}
        renderValue={(value) =>
          `${value.length} ${label.replace("Filter ", "")}${
            value.length > 1 ? "s" : ""
          } selected`
        }
      >
        <List dense>
          {options.map((option) => (
            <ListItem key={option.value}>
              <ListItemText primary={option.value} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="start"
                  value={option.value}
                  checked={option.checked}
                  onChange={handleCheckbox}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Select>
    </FormControl>
  )
}
