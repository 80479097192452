import { makeStyles, fade } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  root: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
  },
  inputRoot: {
    padding: theme.spacing(1.1875),
    fontSize: "0.875rem",
    width: "100%",
    "@media (max-width: 768px)": {
      "&::placeholder": {
        color: "transparent",
      },
    },
  },
  searchIcon: {
    paddingLeft: theme.spacing(1),
  },
  resultBoxRoot: {
    // ! Este es el cuadro de busqueda
    // width: "auto",
    minWidth: 1200,
    zIndex: 2,
    "@media (max-width: 768px)": {
      width: "auto",
      minWidth: "auto",
    },
  },
  progressRoot: {
    width: "auto",
    padding: theme.spacing(2),
    "@media (max-width: 768px)": {
      width: 300,
    },
  },
  listRoot: {
    // ! La lista dentro del cuadro de busqueda
    // width: 500,
    maxHeight: 500,
    position: "relative",
    overflowY: "auto",
    "@media (max-width: 768px)": {
      width: "375px",
    },
  },
  editAppointmentBtn: {
    background: "none",
    color: theme.palette.primary.main,
    textAlign: "left",
    border: 0,
    padding: "0.2rem 0",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  typography: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media (max-width: 768px)": {
      fontSize: ".7rem",
    },
  },
  fontWeightBold: {
    fontWeight: "bold",
    "@media (max-width: 768px)": {
      width: "40%",
      fontSize: ".7rem",
    },
  },
  ResultAppointmentContainer: {
    "& .MuiTypography-body1": {
      color: ({ Cancel }) => (Cancel ? "#606060" : "#3F3F3F"),
    },
    "@media (max-width: 768px)": {
      width: "200",
      fontSize: "1rem",
      display: "flex",
      flexDirection: "row",
      marginRight: "2rem",
    },
  },
  buttonStyle: {
    width: "100%",
    height: "28,49px",
    backgroundColor: "#00BEB5",
    color: "white",
    wordWrap: "break-word",
    justifyContent: "center",
    textAlign: "center",
    //fontSize: "12px",
    padding: "5px",
    margin: "0.25rem 0",
  },
  listItem: {
    backgroundColor: ({ Cancel }) => (Cancel ? "#dedede" : "inherit"),
  },
}))
