import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    container: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gridTemplateRows: "2fr",
        gap: "0px 0px",
        zIndex: "11",
        margin: "0.25rem 0",
        // width: "100%",
        // height: "auto",
        fontFamily: [
            "Gotham A", 
            "Gotham B", 
            "Franklin Gothic", 
            "Arial", 
            "Helvetica",
            "sans-serif"
          ].join(','),
        width: "100%",
    },
    leftBlock: {
        display: 'block',
        backgroundColor: "#C4C4C4",
        padding: ".8rem",
        fontSize: "12px",
        textAlign: "center",
        borderRight: "solid 2px #00BEB5",
        letterSpacing: ".5px",
    },
    rightBlock: {
        display: "inline",
        backgroundColor: "#F1F1F1",
        padding: ".8rem",
        textAlign: "center",
        wordWrap: "break-word",
        fontSize: "16px"
    }
}))

export const DataBlock = ({dataName, content}) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.leftBlock}>
                {dataName}
            </div>
            <div className={classes.rightBlock}>
                {content}
            </div>
        </div>
    )
}
