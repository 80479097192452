import { TextField } from "@material-ui/core"
import React from "react"

const NumberInput = (props) => {
  return (
    <TextField
      type="number"
      variant="outlined"
      fullWidth
      size="small"
      inputProps={{
        min: 0,
      }}
      {...props}
    />
  )
}

export default NumberInput
