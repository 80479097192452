export default ((env) => {
  switch (env) {
    case "production":
      return {
        newFormId: "220185072637656",
        src: "https://patienteducationgenius.jotform.com",
        autoUpdateDelayInMilliseconds: 600000,
      };
    case "development":
      return {
        // newFormId: '212986469450669',
        newFormId: "242695583237972",
        src: "https://patienteducationgenius.jotform.com",
        autoUpdateDelayInMilliseconds: 600000,
      };
    default:
      return {};
  }
})(process.env.NODE_ENV || "development");
