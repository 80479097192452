import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { Cancel, Delete } from "@material-ui/icons"
import moment from "moment"

// const StyledCancelButton = withStyles({
//   root: {
//     backgroundColor: '#ef5350',
//     color: '#ffffff',
//     '&:hover': {
//       backgroundColor: '#b81411',
//     },
//   },
// })(Button)

const CancelButton = ({
  classStyle,
  setNotificationMessage,
  setNotificationOpen,
  setNotificationStatus,
  cancelAppointment,
  updateAppointments,
  deleteAppt,
  appointment,
}) => {
  const deleteButtonText = deleteAppt ? "Delete" : "Cancel"
  const actionNotification = deleteAppt ? "Deleting" : "Cancelling"
  const successNotification = deleteAppt ? "deleted" : "cancelled"

  const {
    AppointmentID,
    FirstName,
    LastName,
    SubmissionID,
    SelectProvider,
    PatientEMRNumber,
    AppointmentType,
    InPersonOrTelehealth,
    AppointmentStartTime,
    ClinicName,
    Email,
    Phone,
  } = appointment

  const handleCancelButton = async () => {
    setNotificationMessage(`${actionNotification} Appointment...`)
    setNotificationStatus("info")
    setNotificationOpen(true)
    const value = await cancelAppointment({
      SubmissionID,
      APPID: AppointmentID,
      firstName: FirstName,
      lastName: LastName,
      patientEMRNumber: PatientEMRNumber,
      provider: SelectProvider,
      apptType: AppointmentType,
      inPersonOrTelehealth: InPersonOrTelehealth,
      apptDateTime: moment(AppointmentStartTime).format("YYYY-MM-DDThh:mm:ss"),
      clinicName: ClinicName,
      email: Email,
      phone: Phone,
    })
    setNotificationOpen(false)
    if (value) {
      setNotificationMessage(`Appointment ${successNotification} successfully!`)
      setNotificationStatus("success")
    } else {
      setNotificationMessage("ERROR WITH SERVER")
      setNotificationStatus("error")
    }
    setNotificationOpen(true)
    updateAppointments()
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      className={classStyle}
      onClick={handleCancelButton}
      endIcon={deleteAppt ? <Delete /> : <Cancel />}
    >
      {deleteButtonText}
    </Button>
  )
}

export default CancelButton
