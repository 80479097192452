import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function ErrorNotification({ title, message = "", open, onClose, severity }) {
  return open ? (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
      onClose={onClose}
    >
      <Alert severity={severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            onClose(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }>
        <AlertTitle>{title}</AlertTitle>
      </Alert>
    </Snackbar>
  ) : null;
}

ErrorNotification.propTypes = {};

export default ErrorNotification;
